import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { UIWindow, RatioMenu } from "./ui-kit";
import _ from 'lodash';
import { actions } from "../actions";
import { EditorContext } from "./page-editor";
import { FRONTEND_DATA } from "../globals";
// import { HotKey } from "./ui-kit";
import { HotKey } from "@cargo/ui-kit/hotkey/hotkey";
import { withRouter } from 'react-router-dom';

class UIWindowLayer extends Component {
	constructor(props) {

		super(props);

		this.clickOriginInAdmin = null;

		this.state ={
			width: 1280,
			height: 720
		}

		this.windowLayerRef = React.createRef();
		this.handleGlobalClick = this.handleGlobalClick.bind(this);

	}

	render() {

		return (
			<>
				{ this.props.viewport === 'mobile' ? ( <RatioMenu /> ) : ( null) }

				<div ref={this.windowLayerRef} id="uiWindow-layer">
						{
							_.map(this.props.uiWindows.byId, (uiWindow, id) => {

								const component = React.isValidElement(uiWindow.component) 
									? React.cloneElement(uiWindow.component, uiWindow.props) 
									: React.createElement(uiWindow.component, uiWindow.props);

								return <UIWindow 
									key={id} 
									id={id}
									windowSize={this.state} 
									{...uiWindow.props}
								>
									{component}
								</UIWindow>;

							})
						}
				</div>

				<HotKey 
					shortcut="esc"
					config={{ keyCode: 27 }}
					callback={() => {
						if( document.querySelector('.context-menu-layer') ||
							this.props.pauseGlobalEventExecution 
						){ 
							return 
						}
	
						this.closeLastFocusedWindow('esc');
					}}
					scope="window"
				/>

			</>
		);

	}

	closeLastFocusedWindow = (eventType) => {

		let windowToRemove = null;

		if ( this.closeOnSingleClickoutWindow 
			&& this.props.uiWindows.focusOrder[0] === this.closeOnSingleClickoutWindow.id 
		) {
			// If top of focus order is ignore clickout, don't close anything.
			// This pauses focus order based clickout until the top most window is closed.
			const windowToCheck = this.props.uiWindows.byId[this.props.uiWindows.focusOrder[0]];
			if( windowToCheck?.props?.ignoreClickout ){
				return
			}

			// this.props.uiWindows.focusOrder[0];
			windowToRemove = this.closeOnSingleClickoutWindow;
		} else {

			// loop over the open windows in focus order
			for(let i = 0; i < this.props.uiWindows.focusOrder.length; i++) {
				const windowToCheck = this.props.uiWindows.byId[this.props.uiWindows.focusOrder[i]];
				// if (windowToCheck?.id === 'color-palette') {
				// 	break;
				// }

				// esc
				if (eventType === 'esc') {
					if (!windowToCheck?.props?.preventEsc) {
						windowToRemove = windowToCheck;
						break;
					}

				// click
				} else {
					if (!windowToCheck?.props?.ignoreClickout) {
						windowToRemove = windowToCheck;
						break;
					}
				}

			}
		}

		if(windowToRemove) {
			this.props.removeUIWindow(uiWindow => uiWindow === windowToRemove);
		}

	}

	updateWindowSize = ()=>{
		
		this.setState({
			width: document.documentElement.clientWidth,
			height: document.documentElement.clientHeight
		});

	}

	componentDidUpdate(prevProps, prevState) {

		this.preventInvokeWindowPointerEvents(prevProps, prevState);

		if (this.props.location.pathname !== prevProps.location.pathname) {
			this.onRouteChange(this.props.location.pathname, prevProps.location.pathname);
		}

	}

	updateWindowLayerState(updates) {

		var currentState = this.props.uiWindowLayer;

		this.props.updateAdminState({
			uiWindowLayer: {...currentState, ...updates}
		});
	}

	// Disables and re-enables pointer events on nested windows
	preventInvokeWindowPointerEvents(prevProps, prevState) {

		// If we have more than one window,
		// and there are more windows than our last update,
		if(  this.props.uiWindows.focusOrder?.length > 1 &&
			 this.props.uiWindows.focusOrder?.length !== prevProps.uiWindows.focusOrder?.length &&
			 this.props.uiWindows.focusOrder?.length > prevProps.uiWindows?.focusOrder?.length ){

			// find the invoke window and disable it.
			// let arrIndex = prevProps.uiWindows.focusOrder.length - 1;
			// let windowToDisable = _.find(this.props.uiWindows.byId, (uiWindow) => { return uiWindow.id === prevProps.uiWindows.focusOrder[arrIndex] });
			let windowToDisable = _.find(this.props.uiWindows.byId, (uiWindow) => { return uiWindow.id === prevProps.uiWindows.focusOrder[0] });
			let newWindow       = _.find(this.props.uiWindows.byId, (uiWindow) => { return uiWindow.id === this.props.uiWindows.focusOrder[0] })

			if( windowToDisable && newWindow && newWindow.props.invokeWindow === windowToDisable.id ){
				windowToDisable.props?.reference?.current?.setAttribute('prevent-events', true);
			}
		}

		// If there are a different number of windows than last update,
		// and there are less windows than our last update,
		if(  this.props.uiWindows.focusOrder?.length !== prevProps.uiWindows.focusOrder?.length  &&
			 this.props.uiWindows.focusOrder?.length !== 0 &&
			 this.props.uiWindows.focusOrder?.length < prevProps.uiWindows?.focusOrder?.length 
		 ){
			// find the invoke window and enable it.
			let windowToEnable = _.find(this.props.uiWindows.byId, (uiWindow) => { return uiWindow.id === this.props.uiWindows.focusOrder[0] });
			windowToEnable?.props?.reference?.current?.removeAttribute('prevent-events');
		}

		// If changing viewports, all bets are off. Allow pointer events on all locked windows.
		if( this.props.viewport !== prevProps.viewport &&
			this.props.uiWindows.focusOrder?.length === prevProps.uiWindows?.focusOrder?.length 
		){
			 _.each(this.props.uiWindows.byId, (uiWindow) => {
				uiWindow?.props?.reference?.current?.removeAttribute('prevent-events');
			});
		}


	}

	getInvokeWindow(props) {
		return _.find(props.uiWindows.byId, (uiWindow) => { return uiWindow.props.invokeWindow });
	}

	componentDidMount(){
		// bind click events in both the admin and content frame
		FRONTEND_DATA.contentWindow.addEventListener("mousedown", this.handleGlobalClick,{capture: true});
		FRONTEND_DATA.contentWindow.addEventListener("pointerup", this.handleGlobalClick);
		FRONTEND_DATA.contentWindow.addEventListener("click", this.handleGlobalClick);
		window.addEventListener("mousedown", this.handleGlobalClick,{capture: true});
		window.addEventListener("pointerup", this.handleGlobalClick);
		window.addEventListener("click", this.handleGlobalClick);

		// resize
		window.addEventListener('resize', this.updateWindowSize)
		this.updateWindowSize();
	}

	componentWillUnmount(){
		// unbind click events in both the admin and content frame
		FRONTEND_DATA.contentWindow.removeEventListener("mousedown", this.handleGlobalClick, {capture: true});
		FRONTEND_DATA.contentWindow.removeEventListener("pointerup", this.handleGlobalClick);
		FRONTEND_DATA.contentWindow.removeEventListener("click", this.handleGlobalClick);
		window.removeEventListener("mousedown", this.handleGlobalClick, {capture: true});
		window.removeEventListener("pointerup", this.handleGlobalClick);
		window.removeEventListener("click", this.handleGlobalClick);

		// resize
		window.removeEventListener('resize', this.updateWindowSize)
	}

	handleGlobalClick = (event) => {

		let target = event.target;
		let type   = event.type;
		let inAdmin = document.contains(target);
		let clickInCheckboard = target && target.classList?.contains('mobile') && this.props.viewport === 'mobile';

		if ( (target && target.classList?.contains('ignore-clickout') ) || ( target && target.closest?.('.ignore-clickout') !== null )) return;

		if (type === 'mousedown') {

			this.clickOriginInAdmin = inAdmin;

			// if there is a window allowing all clickouts (regardless of target)
			if (this.hasWindowAllowingAllClickout()) {
				this.closeAllClickoutWindow(target);
			}

			// is there a modal that requires a single clickout? close it
			// Note: this is essentially like a 'clickout layer' that allows scroll
			if (this.hasWindowRequiringSingleClickout(target)) {
				this.closeSingleClickoutWindow(target);
				return;
			}

			// SECOND, is the click in the admin or in the content frame?
			// they are handled separately
			if ( ( inAdmin || this.clickOriginInAdmin ) && !clickInCheckboard ) {
				// We're clicking in the admin
				this.handleClickInAdmin(target);

			} else if( !inAdmin || !this.clickOriginInAdmin || clickInCheckboard ) {

				// clicking in the content frame
				if ( this.hasActiveEditor() && !this.props.pauseGlobalEventExecution ) {
					this.handleEditorWindowClickout(target);

				} else {
					// allow links to propagate normally without closing any windows
					if (event.target.nodeName === 'A' || event.target.closest?.('a') !== null || this.props.pauseGlobalEventExecution ) {
						// do nothing
					} else {
						// CLOSE ALL WINDOWS by default
						this.closeAllWindows(target);
					}
				}

			}


		}

	}

	closeAllWindows(target) {

		// clicking out in the admin will close all windows if not clicking on an 'interactive' element
		if ( this.clickTargetIsNotAdminInterface(target) ) {
			this.props.removeUIWindow(uiWindow => {
				return !uiWindow.props.ignoreClickout;
			});
		}
	}

	handleEditorWindowClickout(target) {

		let activeEditorRange = _.get(this.context, 'range');
		let currentFocusedWindow = _.find(this.props.uiWindows.byId, (uiWindow) => { return uiWindow.id === this.props.uiWindows.focusOrder[0] });
		let closeCurrentWindowViaFormattingCommand = currentFocusedWindow?.props?.closePrioritizedByCommand ? true : false;
		let clickOnMobileEditorBG = target?.id === 'device-viewport';

		// ignore logic below if the currently focused window ignores editor window clicks
		if (currentFocusedWindow?.props?.ignoreEditorWindowClickout === true) {return;}

		if (activeEditorRange) {
			// click originated INSIDE of an editor
			if ( this.context.editorHasFocus || closeCurrentWindowViaFormattingCommand ) {

				if (
					this.props.uiWindows.byGroup['formatting'] !== undefined && 
					this.props.uiWindows.byGroup['formatting'].length > 0 
					||
					closeCurrentWindowViaFormattingCommand
				) {
					const currentFormattingWindow = this.getWindowByID(this.props.uiWindows.byGroup['formatting'][0]);
					const commandIsAppliedToNewRange = activeEditorRange.commands[currentFormattingWindow.props.windowName]?.isApplied;

					if (commandIsAppliedToNewRange) {

						if( clickOnMobileEditorBG ){
							this.closeAllWindows(target);
							return 
						}
						// leave the window open, BUT close other windows
						this.props.removeUIWindow(uiWindow => {
							return uiWindow.group !== 'formatting' && !uiWindow.props.requireDiscreteClose;
						});

						return;

					} else {
						this.closeAllWindows(target);
						return;
					}

				}

				if (currentFocusedWindow?.props?.invoked) {
					this.closeLastFocusedWindow();
				} else {
					// do this by default
					this.closeAllWindows(target);
				}

			} else {
				this.closeAllWindows(target);

			}
		}
	}

	hasWindowRequiringSingleClickout = (target) => {

		let foundInFocusOrder = false;

		let closeOnSingleClickoutWindow = _.find(this.props.uiWindows.byId, (uiWindow) => { 
			return uiWindow.props.closeOnSingleClickout === true && uiWindow.id === this.props.uiWindows.focusOrder[0]
		});

		if( closeOnSingleClickoutWindow ){
			foundInFocusOrder = true;
		}
		// If the first item in the focus order isn't single clickout
		if( !closeOnSingleClickoutWindow ){
			// try to find one that is.
			closeOnSingleClickoutWindow = _.find(this.props.uiWindows.byId, (uiWindow) => { return uiWindow.props.closeOnSingleClickout === true});
		}

		// is there a modal that requires a single clickout?
		if (closeOnSingleClickoutWindow && !closeOnSingleClickoutWindow?.props?.reference?.current?.contains(target)) {

			if( !foundInFocusOrder ){
				console.warn('Closed UiWindow not first in focus order.')
			}
			// yes? close it.
			this.closeOnSingleClickoutWindow = closeOnSingleClickoutWindow;

			return true;
		}

		return false;
	}


	hasWindowAllowingAllClickout = () => {
		const closeOnAllClickoutWindow = _.find(this.props.uiWindows.byId, (uiWindow) => { return uiWindow.props.closeOnAllClickout === true});
		// FIRST – is there a modal that requires a single clickout?
		if ( closeOnAllClickoutWindow ) {
			// yes? close it.
			this.closeOnAllClickoutWindow = closeOnAllClickoutWindow;

			return true;
		}

		return false;
	}

	closeAllClickoutWindow = (target) => {

		// if the target is not inside the window itself
		if (
			!this.closeOnAllClickoutWindow?.props?.reference?.current?.contains(target)
			&& this.clickTargetIsNotAdminInterface(target)
		) {

			// do not allow if window was just dragged
			if (this.closeOnAllClickoutWindow.props.dragging) return;

			this.props.removeUIWindow(uiWindow => {
				return uiWindow === this.closeOnAllClickoutWindow;
			});

			this.closeOnAllClickoutWindow = null;

		}
	}

	closeSingleClickoutWindow = (target) => {

		// Special escape hatch for global event execution pause in this case
		// Only used when we are previewing quick view and have the color picker open.
		let ignoreGlobalEventExecutionLockout = this.props.quickView.mode === 'preview' && this.props.uiWindows.focusOrder[0] === 'color-palette';
		let secondaryCheckForExecutionLockout = this.props.quickView.mode === 'preview' && this.props.uiWindows.focusOrder[0] !== 'color-palette';
		
		// Don't close anything if we're clicking on the device menu
		if( !ignoreGlobalEventExecutionLockout && target.closest('#device-menu') ){
			return
		}

		// Prevents click execution while overlay elements like the context menu or alert modal are active.
		if( this.props.pauseGlobalEventExecution && !ignoreGlobalEventExecutionLockout && !secondaryCheckForExecutionLockout ){
			return
		}

		if (!this.closeOnSingleClickoutWindow?.props?.reference?.current?.contains(target)) {

			let currentFocusedWindow = _.find(this.props.uiWindows.byId, (uiWindow) => { return uiWindow.id === this.props.uiWindows.focusOrder[0] });

			if(  currentFocusedWindow ){

				if( currentFocusedWindow?.props?.reference?.current?.contains(target) ) {
					return 
				}

				if( currentFocusedWindow?.props?.dragging ){
					return
				}
			}

			// check if the target is (or is within) the "invoke target" (The element clicked on to open the window)
			// prevent the click from counting as a close
			if ( this.closeOnSingleClickoutWindow?.props?.invokeTarget ) {
				let invokeTarget = this.closeOnSingleClickoutWindow?.props?.invokeTarget;
				if (invokeTarget.contains(target) || invokeTarget === target) {
					return
				}
			}

			// do not allow if window was just dragged
			if (this.closeOnSingleClickoutWindow.props.dragging) {
				return
			};

			// Close last focused window in order.
			this.closeLastFocusedWindow();

			this.closeOnSingleClickoutWindow = null;

		}
	}

	handleClickInAdmin(target) {

		// mobile viewport boundaries

		// Close last focused window if click is on device menu.
		// if (target && target.closest('#device-menu') !== null) {
		// 	this.closeLastFocusedWindow();
		// }


			// click orignated inside the window layer
		if ( this.windowLayerRef?.current?.contains(target)) {

			let currentFocusedWindow = _.find(this.props.uiWindows.byId, (uiWindow) => { return uiWindow.id === this.props.uiWindows.focusOrder[0] });
			if (currentFocusedWindow?.props?.invoked && target.classList.contains('uiWindow')) {
				this.closeLastFocusedWindow();
			}
		
		} else {
			
			let isInterfaceBar = target.closest('.top-menu-bar') || target.closest('#right-menu-bar');
			// this is bad, but when you drag pointer events need to be prevented
			if (target.nodeName === 'HTML' || this.clickOriginInAdmin && !isInterfaceBar ) {
				return;
			}
			
			this.closeAllWindows(target);
		}
		

	}

	clickTargetIsNotAdminInterface(target) {
		let inEditorInterface = target.closest('#editor-overlay')
		let button        = target.closest('button')
		let label         = target.closest('label')
		let input         = target.closest('input')
		let uiElem        = target.closest('.ui-element')
		let clickoutLayer = this.isTargetClickoutLayer(target);

		return inEditorInterface === null
			&& button === null 
			&& label === null 
			&& input === null 
			&& uiElem === null 
			&& !clickoutLayer;

	}

	isTargetClickoutLayer(target) {
		return target && target?.classList.contains('clickout-layer') || target && target?.closest('.clickout-layer');
	}

	onRouteChange(path, prevPath) {

		if( prevPath === '/commerce' ){
			// This is not helpful for commerce
			return
		}

		// close ALL windows when changing admin routes
		this.props.removeUIWindow(uiWindow => {
			return !uiWindow.props.ignoreClickout && !this.getWindowByID('page-list-window');
		});

		// close windows with conflicting clickout categories. 
		// windows with both 'ignoreClickout' and 'closeOnSingleClickout' 
		// pause focus order based clickout until the top most window is closed.
		this.props.removeUIWindow(uiWindow => {
			return uiWindow.props.ignoreClickout && uiWindow.props.closeOnSingleClickout;
		});
	}

	getWindowByID(windowID) {
		return this.props.uiWindows.byId[windowID]
	}

	hasActiveEditor() {
		return this.props.PIDBeingEdited
	}

	getActiveEditorRange() {
		return ;
	}

}

// Listens to page editor context
UIWindowLayer.contextType = EditorContext;

function mapReduxStateToProps(state, ownProps) {

	return {
		uiWindows: state.uiWindows,
		viewport: state.adminState.viewport,
		pauseGlobalEventExecution: state.adminState.pauseGlobalEventExecution,
		uiWindowLayer: state.adminState.uiWindowLayer,
		viewportIsMobile: state.adminState.viewport === 'mobile',
		PIDBeingEdited: state.frontendState.PIDBeingEdited,
		quickView: state.frontendState.quickView
	};

}

function mapDispatchToProps(dispatch) {

	return bindActionCreators({
		updateAdminState: actions.updateAdminState,
		addUIWindow: actions.addUIWindow,
		removeUIWindow: actions.removeUIWindow,
		updateUIWindow: actions.updateUIWindow
	}, dispatch);

}

export default withRouter(
	connect(
		mapReduxStateToProps, 
		mapDispatchToProps
	)(UIWindowLayer)
);