import React, { Component } from 'react';
import { EditorContext } from "../page-editor";
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actions } from "../../actions";
import { paths } from "../../router";
import { commands } from "../../lib/inline-editor";
import { FRONTEND_DATA } from "../../globals";
import { HotKey } from "@cargo/ui-kit/hotkey/hotkey";
import { Formik, Field } from 'formik';
import FormikChangeListener from "../ui-kit/formik-change-listener";
import { Select } from '@cargo/ui-kit';
import FormattingButton from "./formatting-button";
import { TextStyleDefaults } from "../../defaults/text-style-defaults";
import { getAllTextStyles} from '../../lib/inline-editor/helpers'
import { MenuContext } from "@cargo/common/context-menu";

import { FormattingTextStylesContextUI } from "./formatting-text-styles-context-ui";

import _ from 'lodash';
import { subscriptions as textStyleSubscriptions } from "../../lib/shared-css/global-css-subscriptions";

class FormattingTextStylesSelect extends Component {

	constructor(props) {
		super(props);

		this.state = {
			selectActive: false,
			currentTypeStyle: getAllTextStyles()[0]
		}

		this.buttonState = 'unavailable';

	}

	toggleUIWindow = (event, options) => {

		if (!_.find(this.props.openUIWindows, uiWindow => _.get(uiWindow, 'id') === options.windowId)) {
			this.props.addUIWindow({
				group: options.group,
				component: options.component,
				id: options.windowId,
				props: {
					type: options.uiWindowType, 
					positionType: options.positionType, 
					borderRadius: options.borderRadius, 
					autoHeight: options.autoHeight, 
					// buttonPos: _.merge({}, buttonPos), 
					tabbed: options.tabbed,
					windowName: options.windowName,
					openingTab: options.openingTab,
					showNewTextStyleFlow: options.showNewTextStyleFlow,
					selectedTextStyle: options.selectedTextStyle
				}
			},{
				removeGroup: true,
				removeGroupByName: options.removeGroupName
			});
		} else {
			this.props.updateUIWindow(
				options.windowId,
				{
					tabbed: options.tabbed,
					openingTab: options.openingTab,
					showNewTextStyleFlow: options.showNewTextStyleFlow,
					selectedTextStyle: options.selectedTextStyle
				}
			)
		}

	}

	changeTypeStyle = (typeStyle) => {

		let typeStyleCommand = null; 

		// CLASS selecter (CUSTOM)
		if(typeStyle.startsWith('.')){
			//  We're editing a class selector, ex: ".my-style". 
			// Apply the textstyles command
			typeStyleCommand = "textstyles";
			const { isAllowed, isApplied } = this.context.range.commands[typeStyleCommand];
			if(isAllowed){
				const className = typeStyle.substring(1, typeStyle.length );
				commands[typeStyleCommand].execute(className);
			}

		// HEADINGS
		} else if(typeStyle.startsWith('h')){
			
			// 'h1', 'h2', 'h3' commands
			typeStyleCommand = typeStyle
			const activeCommand = this.context.range.commands[typeStyleCommand]
			if(activeCommand && activeCommand.isAllowed && commands[typeStyleCommand]){
				commands[typeStyleCommand].execute();
			}

		} else if (typeStyle === "bodycopy") {

			// Handle removing parents / headers / spans / etc
			if(this.state.currentTypeStyle.tag !== "bodycopy"){
				commands["textstyles"].execute(null);
			}

		}

	}

	getAppliedTextStyle = () => {

		// only run if we have a new range
		if(this.context.range !== this.lastRange) {

			const allTextStyles = getAllTextStyles();
			const tagBasedTextStyles = allTextStyles.filter(style => !style.hasOwnProperty('className'));

			const appliedCommands = [];

			_.each(this.context.range.commands, (state, command) => {

				if(state.isApplied) {

					if(command === "textstyles") {

						Object.keys(state.matches).forEach(matchedClassName => {

							appliedCommands.push(
								allTextStyles.find(style => style.className && style.className == matchedClassName)
							)

						})

					} else {

						const appliedTagStyle = tagBasedTextStyles.find(style => style.tag == command);
						if(appliedTagStyle){
							appliedCommands.push(appliedTagStyle);
						}

					}

				}

			})

			this.setState({
				currentTypeStyle: appliedCommands[0] || getAllTextStyles()[0]
			});

			// store last range to check for the next run
			this.lastRange = this.context.range;

		}

	}

	componentDidUpdate(prevProps, prevState){
		this.getAppliedTextStyle();

		if( prevProps.pauseGlobalEventExecution === true 
			&& !this.props.pauseGlobalEventExecution
			&& this.state.selectActive
			&& prevState.selectActive ){
			// If we're resuming global event execution and the select menu is active
			// it's safe to assume we've closed the menu and can remove the active state.
			this.setState({ selectActive: false })
		}
	}

	retrieveButtonState = (newButtonState) => {
		this.buttonState = newButtonState;
	}
	
	render() {
		const { className, activeWindow, tooltip, toolTipIcon, data, } = this.props;
		const classList = `${className !== undefined ? className : ''}`;
		const appliedTypeStyle = this.state.currentTypeStyle.name;

		return (
			<>

			{TextStyleDefaults.map((typeStyle) => 
				typeStyle.shortcut && <HotKey 
					scope="interface"
					shortcut={typeStyle.shortcut}
					config={typeStyle.hotKeyConfig}
					callback={(e) => {
						this.changeTypeStyle(typeStyle.tag);
					}}
					key={typeStyle.shortcut}
				/>
			)}

			<MenuContext.Consumer>
				{(Menu) => 
					<FormattingButton
						commandName = {this.props.commandName}
						alternateCommand = { this.props.alternateCommand ?? undefined }
						tooltip = {this.props.tooltip}
						data = {this.props.data}
						component={Select}
						retrieveButtonState = {(newButtonState)=>this.retrieveButtonState(newButtonState)}
						className={`button-select${this.state.selectActive ? ' select-active' : ''}${appliedTypeStyle === ' Bodycopy' ? '' : ' highlighted'}`}
						id = "text-styles-select"
						label={
							<>
								<label className="select-display">{appliedTypeStyle}</label>
								<div className="select-arrows"><div></div></div>
							</>
						}
						onMouseDown = { e => {
							if (this.buttonState !== 'unavailable') {
								e.preventDefault();

								if( !this.state.selectActive ){
									this.setState({selectActive: true})
								}
								// open the menu
								Menu.openMenu({
									innerUI: <FormattingTextStylesContextUI  
										subscribedSelectors={textStyleSubscriptions.get("text-style-selectors")} 
										textStyleDefaults={TextStyleDefaults} 
										changeTypeStyle={this.changeTypeStyle}
										currentTypeStyle={this.state.currentTypeStyle}
										toggleUIWindow={this.toggleUIWindow}
										offset={null}
									/>,
									type: 'button',
									event: e
								}) 

							}
								
						}}
					/>
				}
			</MenuContext.Consumer>
			</>
		);

	}

	hasTextOptionApplied() {

	}

	onEscape(event){
	  if(event.keyCode === 27) {
	  	if( this.state.selectActive ){
	  		this.setState({selectActive: false})
	  	}
	  }
	}

};

FormattingTextStylesSelect.contextType = EditorContext;

function mapReduxStateToProps(state, ownProps) {
	return {
		pauseGlobalEventExecution: state.adminState.pauseGlobalEventExecution,
		fontCollection: state.fontCollection,
		PIDBeingEdited: state.frontendState.PIDBeingEdited,
		openUIWindows: _.map(state.uiWindows.byGroup['right-menu-bar'], uiWindowID => state.uiWindows.byId[uiWindowID]),
	};
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({
		updateAdminState: actions.updateAdminState,
		addUIWindow: actions.addUIWindow,
		updateUIWindow: actions.updateUIWindow,
		removeUIWindow: actions.removeUIWindow
	}, dispatch);
}

export default connect(
	mapReduxStateToProps, 
	mapDispatchToProps
)(FormattingTextStylesSelect);
